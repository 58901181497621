import { ThemeOptions, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
// import { createMuiTheme } from '@material-ui/core/styles';
import ColorPalette from './ColorPalette';

/**
 * See default theme values here:
 * https://material-ui.com/customization/default-theme/
 */
const LightHouseTheme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1170,
      xl: 1920,
    },
  },
  palette: {
    type: 'light',
    background: { default: ColorPalette.monochrome.white },
    primary: {
      main: ColorPalette.primary.main,
      dark: ColorPalette.primary.dark,
      light: ColorPalette.primary.light,
    },
    secondary: {
      main: ColorPalette.secondary.main,
      dark: ColorPalette.secondary.dark,
      light: ColorPalette.secondary.light,
    },
    success: {
      main: '#4caf50',
    },
    text: {
      primary: ColorPalette.text.primary,
      secondary: ColorPalette.text.secondary,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: false,
      disableTouchRipple: false,
    },
  },
  shape: {
    borderRadius: 0,
  },
  spacing: 10,
  typography: {
    fontFamily: 'HaufeMerriweatherSansLt, Arial',
    h1: {
      color: ColorPalette.text.primary,
      fontFamily: 'HaufeMerriWeatherSansLt, Arial',
      fontSize: 28,
      marginBottom: 40,
      overflowWrap: 'break-word',
      textTransform: 'uppercase',
      '@media (min-width: 600px)': {
        fontSize: 34,
      },
    },
    h2: {
      fontFamily: 'HaufeMerriWeatherSansLt, Arial',
      fontSize: 22,
      marginBottom: 25,
      marginTop: 55,
      overflowWrap: 'break-word',
    },
    h3: {
      fontFamily: 'HaufeMerriWeatherSans, Arial',
      fontSize: 16,
      fontWeight: 700,
      marginBottom: 0,
      overflowWrap: 'break-word',
      '@media (min-width: 600px)': {
        fontSize: 20,
      },
    },
    h6: {
      fontFamily: 'HaufeMerriWeatherSansLt, Arial',
      fontSize: 18,
      marginBottom: 15,
      marginTop: 10,
      overflowWrap: 'break-word',
      fontWeight: 'bold',
    },
    body1: {
      color: ColorPalette.text.primary,
    },
    subtitle1: {
      fontFamily: 'HaufeMerriWeatherSans, Arial',
      fontWeight: 700,
    },
    overline: {
      color: ColorPalette.monochrome.grey70,
      lineHeight: 1.75,
      textTransform: 'none',
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: 'none',
        '&:hover, &:active': {
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: ColorPalette.primary.light,
        },
        fontWeight: 600,
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: ColorPalette.secondary.light,
        },
        fontWeight: 600,
      },
      iconSizeSmall: {
        '& > *:first-child': {
          fontSize: 16,
        },
      },
      outlinedSecondary: {
        color: ColorPalette.monochrome.grey60,
        border: `1px solid ${ColorPalette.monochrome.grey60}`,
        '&:hover': {
          border: `1px solid ${ColorPalette.monochrome.grey90}`,
          backgroundColor: 'transparent',
          color: ColorPalette.monochrome.grey90,
        },
      },
      text: {
        fontFamily: 'HaufeMerriWeatherSans, Arial',
        textTransform: 'none',
      },
    },
    MuiCard: {
      root: {
        borderRadius: 0,
        boxShadow: '0 3px 8px 0 rgba(0,0,0,0.1)',
      },
    },
    MuiCardContent: {
      root: {
        padding: 20,
        '@media (min-width: 600px)': {
          padding: 30,
        },
        '&:last-child': {
          paddingBottom: 20,
          '@media (min-width: 600px)': {
            paddingBottom: 30,
          },
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: 0,
      },
      label: {
        fontWeight: 600,
        lineHeight: 1.2,
      },
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          scrollBehavior: 'smooth',
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: ColorPalette.monochrome.grey70,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 40,
        color: ColorPalette.monochrome.white,
      },
    },
    MuiToolbar: {
      root: {
        backgroundColor: ColorPalette.monochrome.white,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.12)',
      },
      root: {
        '&.MuiAlert-standardInfo': {
          border: '1px solid #c2d2de',
        },
      },
    },
    MuiLinearProgress: {
      root: {
        height: 6,
      },
      colorSecondary: {
        backgroundColor: ColorPalette.monochrome.grey20,
      },
      barColorSecondary: {
        backgroundColor: ColorPalette.colors.emerald,
      },
    },
    MuiStepIcon: {
      root: {
        '&$active': {
          '&$completed': {
            color: ColorPalette.colors.emerald,
          },
        },
      },
    },
    MuiDrawer: {
      paper: {
        // backgroundColor: ColorPalette.monochrome.grey80
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 600,
      },
      footer: {
        borderBottom: 'none',
      },
    },
    MuiTableContainer: {
      root: {
        // boxShadow: 'none',
        padding: 20,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        maxWidth: 'none',
      },
    },
  },
};

export default createMuiTheme(LightHouseTheme);
