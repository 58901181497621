import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tabContent: {
    backgroundColor: '#FFF',
    padding: '12px',
    width: '100%',
  },
}));

interface ITabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export const TabPanel = ({ children, index, value, ...other }: ITabPanelProps) => {
  const classes = useStyles();
  return (
    <Box
      role="tabpanel"
      className={classes.tabContent}
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Box>
  );
};
