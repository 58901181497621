import { useQuery } from '@tanstack/react-query';
import { getApiService } from '../api/api-request';

const assetsKeys = {
  all: (lang: string) => ['assets', lang],
  postPresignedUrl: (key: string, lang: string, method: string) => ['asset', key, lang, method],
};

export const useAssetsQuery = (getJWT: () => Promise<string | undefined | null>, lang: string) =>
  useQuery({
    queryKey: assetsKeys.all(lang),
    queryFn: () => getApiService(getJWT).getAssets(lang),
  });

export const usePostPresignedUrl = (
  getJWT: () => Promise<string | undefined | null>,
  key: string,
  lang: string,
  method: 'GET' | 'PUT'
) =>
  useQuery({
    queryKey: assetsKeys.postPresignedUrl(key, lang, method),
    queryFn: async () => await getApiService(getJWT).postPresignedUrl(key, lang, method),
    enabled: false,
  });
