import { Box, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core';
import { FunctionComponent, useContext, useState } from 'react';
import { FileUploadItem } from './fileUploadItem';
import { Alert } from '@material-ui/lab';
import { Trans } from 'react-i18next';
import { AlertContext } from '../alert-snackbar/alert-context';
import { useAssetsQuery } from '../../queries/assets-query';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(4),
    backgroundColor: '#e1e1e1',
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  selectLanguage: {
    width: '300px',
  },
}));

interface IAssetManagementProps {
  getJWT: () => Promise<string | undefined | null>;
}

export const AssetManagement: FunctionComponent<IAssetManagementProps> = ({ getJWT }) => {
  const classes = useStyles();
  const [language, setLanguage] = useState('de');
  const { data: assets, isLoading: isLoadingAssets } = useAssetsQuery(getJWT, language);
  const alertContext = useContext(AlertContext);

  const handleOnSuccess = () => {
    alertContext.enqueueAlert({
      type: 'success',
      i18nKey: 'settings.assets.onSuccess',
    });
  };

  const handleOnError = () => {
    alertContext.enqueueAlert({
      type: 'error',
      i18nKey: 'settings.assets.onError',
    });
  };

  const handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLanguage(event.target.value as string);
  };

  return (
    <Box className={classes.wrapper}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl className={classes.selectLanguage} variant="outlined">
            <InputLabel id={'lang-input-label'}>
              <Trans i18nKey={'settings.selectLanguages'} />
            </InputLabel>
            <Select
              labelId={'lang-input-label'}
              label={<Trans i18nKey={'settings.selectLanguages'} />}
              onChange={handleLanguageChange}
              value={language}
            >
              <MenuItem value={'de'}>
                <Trans i18nKey={'settings.languages.de'} />
              </MenuItem>
              <MenuItem value={'en'}>
                <Trans i18nKey={'settings.languages.en'} />
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {isLoadingAssets ? (
          <Grid item xs={12}>
            <Box className={classes.centerContent}>
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          (assets || []).map((asset) => (
            <Grid item md={6} xs={12} key={`item-${asset.key}-de`}>
              <FileUploadItem
                getJWT={getJWT}
                asset={asset}
                onError={handleOnError}
                onSuccess={handleOnSuccess}
                language={language}
              />
            </Grid>
          ))
        )}
        <Grid item xs={12}>
          <Alert severity={'warning'}>
            <Trans i18nKey={'settings.assets.cachingNote'} />
          </Alert>
        </Grid>
      </Grid>
    </Box>
  );
};
