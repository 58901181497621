import { FunctionComponent } from 'react';
import { LwmPage } from '../components/lwm-page';
import { useTranslation } from 'react-i18next';
import { AvvAgreementComponent } from '../components/avv-agreement-component/avv-agreement-component';

export const AvvAgreementsPage: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <LwmPage title={t('pages.avvAgreements')}>
      <AvvAgreementComponent />
    </LwmPage>
  );
};
