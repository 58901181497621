import { FunctionComponent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  makeStyles,
} from '@material-ui/core';
import { Trans } from 'react-i18next';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((_theme) => ({
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '50px',
    gap: '24px',
  },
  fullWidth: {
    width: '100%',
  },
}));

interface IDeleteConfirmationDialogProps {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => Promise<void>;
  warning: JSX.Element;
}
export const DeleteConfirmationDialog: FunctionComponent<IDeleteConfirmationDialogProps> = ({
  open,
  handleClose,
  warning,
  handleDelete,
}) => {
  const classes = useStyles();
  const [deleteFailed, setDeleteFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickDelete = async () => {
    try {
      setIsLoading(() => true);
      setDeleteFailed(() => false);
      await handleDelete();
      handleClose();
    } catch (err) {
      setDeleteFailed(() => true);
    } finally {
      setIsLoading(() => false);
    }
  };

  useEffect(() => {
    if (!open) {
      setDeleteFailed(() => false);
    }
  }, [open]);

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Trans i18nKey={'deleteConfirmation.title'} />
      </DialogTitle>
      <DialogContent>
        <Box className={classes.contentBox}>
          {warning}
          {deleteFailed && !isLoading && (
            <Alert severity={'error'}>
              <AlertTitle>
                <Trans i18nKey={'deleteConfirmation.failed'} />
              </AlertTitle>
            </Alert>
          )}
          {isLoading && <LinearProgress className={classes.fullWidth} />}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <Trans i18nKey={'common.cancel'} />
        </Button>
        <Button variant={'contained'} color={'secondary'} onClick={handleClickDelete}>
          <Trans i18nKey={'common.delete'} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
