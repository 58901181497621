import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography, IconButton, withStyles, Grid, Divider } from '@material-ui/core';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { INotification } from '../../../../sharedTypes';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const Accordion = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    margin: '0px 20px',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '0px 20px',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
    alignItems: 'center',
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    display: 'block',
    padding: '0 20px 30px 20px',
    fontSize: '16px',
    lineHeight: '24px',
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
  },
  newsForm: {
    '& .luSettings-MuiFormControl-root': {
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 700,
    fontFamily: 'HaufeMerriweatherSans',
    fontSize: '1.2rem',
    flexGrow: 1,
  },
  disabled: {
    opacity: 0.5,
  },
  cta: {
    textTransform: 'none',
    fontWeight: 400,
    fontSize: '.8125rem',
    padding: '15px 22px',
    marginTop: 15,
    '& .luSettings-MuiSvgIcon-root': {
      marginLeft: 10,
    },
  },
  disabledHint: {
    fontSize: '.8rem',
    marginLeft: 10,
    fontWeight: 400,
  },
  newLabel: {
    fontWeight: 'normal',
    textTransform: 'uppercase',
    fontSize: '0.65rem',
    marginLeft: '0.5rem',
    color: 'white',
    backgroundColor: '#318551',
    padding: '0.25em 0.4em',
    position: 'relative',
    top: '-4px',
  },
}));

interface INotificationItemProps {
  index: number;
  notification: INotification;
  expanded: string | false;
  totalItems: number;
  onEdit: (...args: any) => void;
  onMove: (...args: any) => void;
  onDelete: (...args: any) => void;
  onExpand: (...args: any) => void;
}

const NotificationItem: FunctionComponent<INotificationItemProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    props.onExpand(newExpanded ? panel : false);
  };

  const { active, translations, isNew } = props.notification;
  const index = props.index;

  return (
    <Accordion key={index} expanded={props.expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
      <AccordionSummary aria-controls="panel-content" id="panel-header">
        {props.expanded === `panel${index}` ? <ExpandMoreIcon /> : <NavigateNextIcon />}

        <Typography className={`${classes.title} ${active ? '' : classes.disabled}`}>
          {translations.de.title}
          {isNew && <span className={classes.newLabel}>Neu</span>}
          {!active && <i className={classes.disabledHint}>{`(${t('deactivated')})`}</i>}
        </Typography>
        <Box sx={{ minWidth: 110, display: 'flex', justifyContent: 'end' }}>
          {props.totalItems > 1 && (
            <>
              <IconButton
                color="primary"
                size="small"
                disabled={index <= 0}
                aria-label="up"
                component="span"
                onClick={(event: any) => props.onMove(event, index, index - 1)}
              >
                <ArrowUpwardIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="primary"
                size="small"
                disabled={index + 1 >= props.totalItems}
                aria-label="down"
                component="span"
                onClick={(event: any) => props.onMove(event, index, index + 1)}
              >
                <ArrowDownwardIcon fontSize="small" />
              </IconButton>
            </>
          )}
          <IconButton
            color="primary"
            size="small"
            aria-label="edit"
            component="span"
            onClick={(event: any) => props.onEdit(event, index, props.notification)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="primary"
            size="small"
            aria-label="delete"
            component="span"
            onClick={(event: any) => props.onDelete(event, index)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={active ? '' : classes.disabled}>
        <small>EN: {translations.en.title}</small>
        <Grid container>
          <Grid item xs style={{ margin: '0 2rem 0 0' }}>
            <Typography variant="h6">{t('settings.languages.de')}</Typography>
            <span
              className={`accordion-content-${index}`}
              dangerouslySetInnerHTML={{ __html: translations.de.content }}
            />
            {translations.de.cta && (
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  component="button"
                  className={classes.cta}
                  onClick={() => window.open(translations.de.cta?.url)}
                >
                  {translations.de.cta?.label} <OpenInNewIcon fontSize="small" />
                </Button>
              </Box>
            )}
          </Grid>
          <Divider orientation="vertical" style={{ opacity: 0.25 }} flexItem />
          <Grid item xs style={{ margin: '0 0 0 2rem' }}>
            <Typography variant="h6">{t('settings.languages.en')}</Typography>
            <span
              className={`accordion-content-${index}`}
              dangerouslySetInnerHTML={{ __html: translations.en.content }}
            />
            {translations.en.cta && (
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  component="button"
                  className={classes.cta}
                  onClick={() => window.open(translations.en.cta?.url)}
                >
                  {translations.en.cta?.label} <OpenInNewIcon fontSize="small" />
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default NotificationItem;
