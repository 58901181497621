import { FunctionComponent, useContext, useState } from 'react';
import { LwmPage } from '../components/lwm-page';
import { useTranslation } from 'react-i18next';
import { AppBar, Box, Tab, Tabs, makeStyles } from '@material-ui/core';
import ColorPalette from '../theme/ColorPalette';
import { TabPanel } from '../components/TabPanel';
import NewsBanner from '../components/settings/banner';
import JWTContext from '../components/JWTContext';
import Notifications from '../components/settings/notifications';
import { AssetManagement } from '../components/settings/assetManagement';

const useStyles = makeStyles((theme) => ({
  tabs: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: ColorPalette.monochrome.grey10,
    '& .MuiTab-root': {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  },
  tabContent: {
    padding: theme.spacing(2),
  },
}));

export const SettingsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const { getJWT } = useContext(JWTContext);

  return (
    <LwmPage title={t('pages.settings')}>
      <Box className={classes.tabs}>
        <AppBar position="static" color="default" elevation={1}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            className={classes.tabs}
          >
            <Tab
              label={t('settings.tabs.banner')}
              id="scrollable-auto-tab-0"
              aria-controls="scrollable-auto-tabpanel-0"
            />
            <Tab
              label={t('settings.tabs.accordion')}
              id="scrollable-auto-tab-1"
              aria-controls="scrollable-auto-tabpanel-1"
            />
            <Tab
              label={t('settings.tabs.assets')}
              id="scrollable-auto-tab-2"
              aria-controls="scrollable-auto-tabpanel-2"
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <NewsBanner getJWT={getJWT} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Notifications getJWT={getJWT} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AssetManagement getJWT={getJWT} />
        </TabPanel>
      </Box>
    </LwmPage>
  );
};
