import { SsoType } from '../../sharedTypes';

type EnvironmentSpecificConfig = {
  API_URL: string;
  APP_URL: string;
};

const dev: EnvironmentSpecificConfig = {
  API_URL: 'http://localhost:8088/dev',
  APP_URL: 'https://dev.lwm.haufe-akademie.de/',
};
const test: EnvironmentSpecificConfig = {
  API_URL: 'https://omsv1pnim5-vpce-0cc31765e64fffec7.execute-api.eu-central-1.amazonaws.com/test',
  APP_URL: 'https://test.lwm.haufe-akademie.de/',
};

const prod: EnvironmentSpecificConfig = {
  API_URL: 'https://bhz5zj00vc-vpce-04301e6b4f30d6e3d.execute-api.eu-central-1.amazonaws.com/prod',
  APP_URL: 'https://manager.lernwelt.haufe-akademie.de/',
};

const env = process.env.REACT_APP_STAGE || 'dev';

const config = env === 'test' ? test : env === 'prod' ? prod : dev;

const mergedConfig = {
  ...config,
  STANDALONE: env === 'dev',
};

export const AvailableSsoTypes: readonly SsoType[] = ['sso', 'ssoWithUserDataComparison'];

export const MICROFRONTEND_ID = 'lwmSettings';

export default mergedConfig;
