import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { getApiService } from '../api/api-request';
import { IPriceList, IPriceListEntry, IPricingItem } from '../../../sharedTypes';
import { IAlertContext } from '../components/alert-snackbar/alert-context';

const priceListsKeys = {
  products: ['price-list-products'] as const,
  pricelist: (product: string) => ['price-list', product],
};

const ascendingPricingComparator = (a: IPricingItem, b: IPricingItem) => a.pieces - b.pieces;

export const usePriceListQuery = (getJWT: () => Promise<string | undefined | null>, product: string, enabled = true) =>
  useQuery({
    queryKey: priceListsKeys.pricelist(product),
    queryFn: () => getApiService(getJWT).getPriceList(product),
    initialData: {
      product,
      pricing: [],
    },
    enabled,
  });

export const usePriceListProductsQuery = (getJWT: () => Promise<string | undefined | null>) =>
  useQuery({
    queryFn: getApiService(getJWT).getProducts,
    queryKey: priceListsKeys.products,
    initialData: [],
  });

interface IDeletePriceListEntryMutation {
  product: string;
  pieces: number;
}
export const useDeletePriceListEntryMutation = (
  getJWT: () => Promise<string | undefined | null>,
  queryClient: QueryClient,
  alertContext: IAlertContext
) =>
  useMutation({
    mutationFn: (params: IDeletePriceListEntryMutation) =>
      getApiService(getJWT).deletePriceListEntry(params.product, params.pieces),

    onMutate: async ({ product, pieces }) => {
      await queryClient.cancelQueries(priceListsKeys.pricelist(product));

      const previousPricelist = queryClient.getQueryData<IPriceList>(priceListsKeys.pricelist(product));

      if (previousPricelist) {
        const pricingArray = previousPricelist.pricing.filter((item) => item.pieces !== pieces);

        queryClient.setQueryData<IPriceList>(priceListsKeys.pricelist(product), {
          product,
          pricing: pricingArray.sort(ascendingPricingComparator),
        });
      }
      return previousPricelist;
    },

    onError: (_err, { product }, context) => {
      alertContext.enqueueAlert({
        type: 'error',
        i18nKey: 'priceLists.deleteEntryFailed',
      });
      if (context) {
        queryClient.setQueryData(priceListsKeys.pricelist(product), context);
      }
    },

    onSettled: (_d, _e, { product }) => {
      queryClient.invalidateQueries({ queryKey: priceListsKeys.pricelist(product) });
    },
  });

export const usePutPriceListMutation = (
  getJWT: () => Promise<string | undefined | null>,
  queryClient: QueryClient,
  alertContext: IAlertContext
) =>
  useMutation({
    mutationFn: (entry: IPriceListEntry) => getApiService(getJWT).putPriceListEntry(entry),

    onMutate: async (entry) => {
      await queryClient.cancelQueries(priceListsKeys.pricelist(entry.product));

      const previousPricelist = queryClient.getQueryData<IPriceList>(priceListsKeys.pricelist(entry.product));

      if (previousPricelist) {
        const pricingArray = previousPricelist.pricing.filter((item) => item.pieces !== entry.pieces);

        queryClient.setQueryData<IPriceList>(priceListsKeys.pricelist(entry.product), {
          product: entry.product,
          pricing: [
            ...pricingArray,
            {
              pieces: entry.pieces,
              price: entry.price,
            },
          ].sort(ascendingPricingComparator),
        });
      }
      return previousPricelist;
    },

    onError: (_err, { product }, context) => {
      alertContext.enqueueAlert({
        type: 'error',
        i18nKey: 'priceLists.saveFailed',
      });
      if (context) {
        queryClient.setQueryData(priceListsKeys.pricelist(product), context);
      }
    },

    onSettled: (_d, _e, { product }) => {
      if (!queryClient.getQueryData<string[]>(priceListsKeys.products)?.includes(product)) {
        queryClient.invalidateQueries({ queryKey: priceListsKeys.products });
      }
      queryClient.invalidateQueries({ queryKey: priceListsKeys.pricelist(product) });
    },
  });

interface IDeletePriceListMutation {
  product: string;
}
export const useDeletePriceListMutation = (
  getJWT: () => Promise<string | undefined | null>,
  queryClient: QueryClient,
  alertContext: IAlertContext
) =>
  useMutation({
    mutationFn: (params: IDeletePriceListMutation) => getApiService(getJWT).deletePriceList(params.product),

    onMutate: async ({ product }) => {
      await queryClient.cancelQueries(priceListsKeys.pricelist(product));

      const previousPricelist = queryClient.getQueryData<IPriceList>(priceListsKeys.pricelist(product));
      const previousProducts = queryClient.getQueryData<string[]>(priceListsKeys.products);

      if (previousPricelist) {
        queryClient.setQueryData<IPriceList>(priceListsKeys.pricelist(product), undefined);
      }
      if (previousProducts) {
        queryClient.setQueryData<string[]>(
          priceListsKeys.products,
          previousProducts.filter((item) => item !== product)
        );
      }
      return { previousPricelist, previousProducts };
    },

    onError: (_err, { product }) => {
      alertContext.enqueueAlert({
        type: 'error',
        i18nKey: 'priceLists.deleteEntryFailed',
      });
      queryClient.invalidateQueries(priceListsKeys.products);
      queryClient.invalidateQueries(priceListsKeys.pricelist(product));
    },

    onSuccess: () => {
      window.location.reload();
    },
  });
