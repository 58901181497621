import {
  Card,
  Grid,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { ChangeEvent, FunctionComponent, useContext, useState } from 'react';
import { IPricingItem } from '../../../../sharedTypes';
import { Trans } from 'react-i18next';
import { AddBox, Delete } from '@material-ui/icons';
import { AlertContext } from '../alert-snackbar/alert-context';
import { useQueryClient } from '@tanstack/react-query';
import {
  useDeletePriceListEntryMutation,
  usePriceListProductsQuery,
  usePriceListQuery,
  usePutPriceListMutation,
} from '../../queries/price-list-queries';

const useStyles = makeStyles((_theme) => ({
  newPricingContainer: {
    padding: '12px',
  },
  newPricingItem: {
    width: '100%',
  },
}));

interface IEditPriceListProps {
  getJWT: () => Promise<string | undefined | null>;
  product: string;
}

export const EditPriceList: FunctionComponent<IEditPriceListProps> = ({ product, getJWT }) => {
  const classes = useStyles();
  const { data: products } = usePriceListProductsQuery(getJWT);
  const { data: priceList, isFetching: isFetchingPriceList } = usePriceListQuery(
    getJWT,
    product,
    products.includes(product)
  );
  const [newPricingPieces, setNewPricingPieces] = useState('');
  const [newPricingPrice, setNewPricingPrice] = useState('');
  const alertContext = useContext(AlertContext);
  const queryClient = useQueryClient();

  const { mutate: deleteEntry } = useDeletePriceListEntryMutation(getJWT, queryClient, alertContext);

  const { mutate: putEntry } = usePutPriceListMutation(getJWT, queryClient, alertContext);

  const validateInputValue = (val: string) => {
    const num = Number.parseInt(val);
    return !Number.isNaN(num) && num >= 0;
  };

  const handleRemovePricing = (pricing: IPricingItem) => {
    deleteEntry({
      product,
      pieces: pricing.pieces,
    });
  };

  const handleInputChange =
    (setter: (val: string) => void) => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setter(event.target.value);
    };

  const handleAddNewPricing = () => {
    const newPricing: IPricingItem = {
      pieces: Number.parseInt(newPricingPieces),
      price: Number.parseInt(newPricingPrice),
    };

    putEntry({
      product: priceList.product,
      ...newPricing,
    });

    setNewPricingPieces('');
    setNewPricingPrice('');
  };

  return (
    <Card>
      {isFetchingPriceList && products.includes(product) && <LinearProgress variant={'indeterminate'} />}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Trans i18nKey={'priceLists.table.piece'} />
            </TableCell>
            <TableCell>
              <Trans i18nKey={'priceLists.table.price'} />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {priceList.pricing.map((item) => (
            <TableRow key={`${priceList.product}-${item.pieces}-table-row`}>
              <TableCell size={'small'}>{item.pieces.toLocaleString()}</TableCell>
              <TableCell size={'small'}>
                {item.price.toLocaleString(undefined, {
                  style: 'currency',
                  currency: 'EUR',
                  maximumFractionDigits: 0,
                })}
              </TableCell>
              <TableCell size={'small'} align="right" padding="none">
                <IconButton
                  onClick={() => {
                    handleRemovePricing(item);
                  }}
                  color={'secondary'}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid container className={classes.newPricingContainer} spacing={2}>
        <Grid item xs={5}>
          <TextField
            type={'number'}
            label={<Trans i18nKey={'priceLists.table.piece'} />}
            variant={'outlined'}
            className={classes.newPricingItem}
            error={!validateInputValue(newPricingPieces)}
            helperText={
              validateInputValue(newPricingPieces) ? undefined : <Trans i18nKey={'priceLists.invalidInputValue'} />
            }
            value={newPricingPieces}
            onChange={handleInputChange(setNewPricingPieces)}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            type={'number'}
            label={<Trans i18nKey={'priceLists.table.price'} />}
            variant={'outlined'}
            className={classes.newPricingItem}
            error={!validateInputValue(newPricingPrice)}
            helperText={
              validateInputValue(newPricingPrice) ? undefined : <Trans i18nKey={'priceLists.invalidInputValue'} />
            }
            value={newPricingPrice}
            onChange={handleInputChange(setNewPricingPrice)}
          />
        </Grid>
        <Grid item>
          <IconButton
            color={'primary'}
            disabled={!(validateInputValue(newPricingPieces) && validateInputValue(newPricingPrice))}
            onClick={handleAddNewPricing}
          >
            <AddBox />
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  );
};
