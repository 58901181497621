import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, FormControlLabel, Grid, Snackbar } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { INotification } from '../../../../sharedTypes';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Alert, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
  },
  newsForm: {
    '& .luSettings-MuiFormControl-root': {
      marginBottom: theme.spacing(2),
    },
  },
  colorPicker: {
    marginBottom: theme.spacing(2),
    marginRight: '40px',
    '& .sample': {
      position: 'absolute',
      right: '-40px',
      bottom: 0,
      width: 24,
      height: 24,
      marginLeft: '.5rem',
    },
  },
  editor: {
    border: '1px solid #999',
    marginBottom: '1.5rem',
    marginTop: '1.5rem',
    padding: '15px',
    minHeight: '150px',
  },
  toggleButton: {
    marginBottom: '2rem',
    '& .lwmSettings-MuiToggleButton-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    '& .lwmSettings-MuiToggleButton-root': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
}));

interface INotificationFormProps {
  index: number;
  notification?: INotification;
  onSubmit: (...args: any) => void;
  onModalClose: (...args: any) => void;
}

const NotificationForm: FunctionComponent<INotificationFormProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [titleDE, setTitleDE] = useState<string | undefined>(props.notification?.translations?.de.title);
  const [contentDE, setContentDE] = useState<string | undefined>(props.notification?.translations?.de.content);
  const [ctaLabelDE, setCtaLabelDE] = useState<string | undefined>(props.notification?.translations?.de.cta?.label);
  const [ctaUrlDE, setCtaUrlDE] = useState<string | undefined>(props.notification?.translations?.de.cta?.url);

  const [titleEN, setTitleEN] = useState<string | undefined>(props.notification?.translations?.en.title);
  const [contentEN, setContentEN] = useState<string | undefined>(props.notification?.translations?.en.content);
  const [ctaLabelEN, setCtaLabelEN] = useState<string | undefined>(props.notification?.translations?.en.cta?.label);
  const [ctaUrlEN, setCtaUrlEN] = useState<string | undefined>(props.notification?.translations?.en.cta?.url);

  const [active, setActive] = useState<boolean>(props.notification?.active || false);
  const [isNew, setIsNew] = useState<boolean>(props.notification?.isNew || false);

  const [ctaDE, setCtaDE] = useState<boolean>(!!props.notification?.translations?.de.cta || false);
  const [ctaEN, setCtaEN] = useState<boolean>(!!props.notification?.translations?.en.cta || false);

  const [validationError, setValidationError] = useState<string | null>(null);

  const [editorStateDE, setEditorStateDE] = useState(() => {
    const blocksFromHTML = convertFromHTML(props.notification?.translations?.de.content || '');
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    return EditorState.createWithContent(state);
  });

  const [editorStateEN, setEditorStateEN] = useState(() => {
    const blocksFromHTML = convertFromHTML(props.notification?.translations?.en.content || '');
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    return EditorState.createWithContent(state);
  });

  const [lang, setLang] = useState<'en' | 'de'>('de');

  useEffect(() => {
    const rawContentState = convertToRaw(editorStateDE.getCurrentContent());
    setContentDE(draftToHtml(rawContentState));
  }, [editorStateDE]);

  useEffect(() => {
    const rawContentState = convertToRaw(editorStateEN.getCurrentContent());
    setContentEN(draftToHtml(rawContentState));
  }, [editorStateEN]);

  const handleSubmit = () => {
    if (titleDE && titleEN) {
      props.onSubmit(props.index, {
        ...props.notification,
        active,
        isNew,
        translations: {
          de: {
            cta:
              ctaDE && ctaUrlDE && ctaLabelDE
                ? {
                    url: ctaUrlDE,
                    label: ctaLabelDE,
                  }
                : null,
            title: titleDE,
            content: contentDE,
          },
          en: {
            cta:
              ctaEN && ctaUrlEN && ctaLabelEN
                ? {
                    url: ctaUrlEN,
                    label: ctaLabelEN,
                  }
                : null,
            title: titleEN,
            content: contentEN,
          },
        },
      });
    } else {
      setValidationError(`
        ${titleDE ? '' : '- Titel auf Deutsch fehlt'}
        ${titleEN ? '' : '- Titel auf Englisch fehlt'}
      `);
    }
  };

  const handleChangeLang = (event: React.MouseEvent<HTMLElement>, newLang: 'de' | 'en') => {
    setLang(newLang);
  };

  return (
    <Box
      style={{ minWidth: 800 }}
      p={2}
      pt={2}
      pb={2}
      display="flex"
      flexDirection="column"
      className={classes.newsForm}
    >
      <Snackbar
        open={validationError !== null}
        autoHideDuration={6000}
        onClose={() => setValidationError(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="error">{validationError}</Alert>
      </Snackbar>
      <Box pb={1} display="flex" justifyContent="end">
        <FormControlLabel
          control={
            <Checkbox
              checked={active}
              onChange={(value) => setActive(value.target.checked)}
              name="active"
              color="primary"
              value={active}
            />
          }
          label={t('settings.accordionForm.activated')}
          style={{ marginRight: '30px' }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isNew}
              onChange={(value) => setIsNew(value.target.checked)}
              name="isNew"
              color="primary"
              value={isNew}
            />
          }
          label={t('settings.accordionForm.newLabel')}
        />
      </Box>

      <ToggleButtonGroup
        color="primary"
        value={lang}
        exclusive
        onChange={handleChangeLang}
        aria-label="Platform"
        className={classes.toggleButton}
      >
        <ToggleButton value="de">{t('settings.languages.de')}</ToggleButton>
        <ToggleButton value="en">{t('settings.languages.en')}</ToggleButton>
      </ToggleButtonGroup>

      {lang === 'de' && (
        <Box>
          <Box>
            <TextField
              multiline={true}
              fullWidth={true}
              id="titleDE"
              label={t('settings.accordionForm.title')}
              defaultValue={titleDE}
              onChange={(value) => setTitleDE(value.currentTarget.value)}
            />
          </Box>
          <Editor
            editorState={editorStateDE}
            wrapperClassName={classes.editor}
            onEditorStateChange={setEditorStateDE}
            placeholder={t('settings.accordionForm.content')}
            toolbar={{
              options: ['inline', 'list', 'textAlign', 'link', 'remove', 'history'],
            }}
          />
          <Box pb={1} display="flex" justifyContent="start" flexDirection="column">
            <FormControlLabel
              style={{ marginBottom: '20px' }}
              control={
                <Checkbox
                  checked={ctaDE}
                  onChange={(value) => setCtaDE(value.target.checked)}
                  name="enabled"
                  color="primary"
                  value={ctaDE}
                />
              }
              label={t('settings.accordionForm.cta')}
            />

            {ctaDE && (
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    id="ctaLabelDE"
                    disabled={!ctaDE}
                    label={t('settings.accordionForm.ctaLabel')}
                    defaultValue={ctaLabelDE}
                    onChange={(value) => setCtaLabelDE(value.currentTarget.value)}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    id="ctaUrlDE"
                    disabled={!ctaDE}
                    label={t('settings.accordionForm.ctaUrl')}
                    defaultValue={ctaUrlDE}
                    onChange={(value) => setCtaUrlDE(value.currentTarget.value)}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      )}

      {lang === 'en' && (
        <Box>
          <TextField
            multiline={true}
            fullWidth={true}
            id="titleEN"
            label={t('settings.accordionForm.title')}
            defaultValue={titleEN}
            onChange={(value) => setTitleEN(value.currentTarget.value)}
          />
          <Editor
            editorState={editorStateEN}
            wrapperClassName={classes.editor}
            onEditorStateChange={setEditorStateEN}
            placeholder={t('settings.accordionForm.content')}
            toolbar={{
              options: ['inline', 'list', 'textAlign', 'link', 'remove', 'history'],
            }}
          />
          <Box pb={1} display="flex" justifyContent="start" flexDirection="column">
            <FormControlLabel
              style={{ marginBottom: '20px' }}
              control={
                <Checkbox
                  checked={ctaEN}
                  onChange={(value) => setCtaEN(value.target.checked)}
                  name="enabled"
                  color="primary"
                  value={ctaEN}
                />
              }
              label={t('settings.accordionForm.cta')}
            />
            {ctaEN && (
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    id="ctaLabelEN"
                    disabled={!ctaEN}
                    label={t('settings.accordionForm.ctaLabel')}
                    defaultValue={ctaLabelEN}
                    onChange={(value) => setCtaLabelEN(value.currentTarget.value)}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    id="ctaUrlEN"
                    disabled={!ctaEN}
                    label={t('settings.accordionForm.ctaUrl')}
                    defaultValue={ctaUrlEN}
                    onChange={(value) => setCtaUrlEN(value.currentTarget.value)}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      )}

      <Box pt={3} display="flex" justifyContent="end">
        <Button
          variant="outlined"
          color="primary"
          component="button"
          style={{ marginRight: '15px' }}
          onClick={() => props.onModalClose()}
        >
          {t('settings.cancel')}
        </Button>
        <Button variant="contained" color="secondary" component="button" onClick={handleSubmit}>
          {t('settings.save')}
        </Button>
      </Box>
    </Box>
  );
};

export default NotificationForm;
