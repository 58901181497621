import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Paper,
  Snackbar,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { getApiService } from '../../api/api-request';
import { INotification } from '../../../../sharedTypes';
import NotificationItem from './notificationItem';
import NotificationForm from './notificationForm';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(4),
    backgroundColor: '#e1e1e1',
  },
}));

interface INotificationsProps {
  getJWT: () => Promise<string | undefined | null>;
}

const Notifications: FunctionComponent<INotificationsProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [notificationsList, setNotificationsList] = useState<INotification[]>([]);
  const [form, setForm] = useState<any>(null);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showConfirmationDelete, setShowConfirmationDelete] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [itemToDelete, setItemToDelete] = useState<number | null>();
  const [expanded, setExpanded] = useState<string | false>(false);

  const apiService = getApiService(props.getJWT);

  const handleSubmitNews = (index: number, notification: INotification) => {
    let items = [...notificationsList];
    items[index] = notification;
    handleSubmit(items);
    setShowForm(false);
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const settings = await apiService.getSettings('Notifications');
        if (settings.data.length > 0) {
          setNotificationsList(settings.data);
        }
      } catch (error) {
        setError(`${t('settings.failAlert')}: ${String(error)}`);
      }
      setLoading(false);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (notificationsList: INotification[]) => {
    setNotificationsList(notificationsList);
    setLoading(true);
    try {
      await apiService.updateSettings('Notifications', notificationsList);
      setShowAlert(true);
    } catch (error) {
      setError(`${t('settings.failAlert')}: ${String(error)}`);
    }

    setLoading(false);
  };

  const handleSnackbar = () => {
    setShowAlert(false);
  };

  const handleDelete = () => {
    setShowConfirmationDelete(false);
    if (itemToDelete || itemToDelete === 0) {
      let items = [...notificationsList];
      items.splice(itemToDelete, 1);
      handleSubmit(items);
      setItemToDelete(null);
    }
  };

  const closeModal = () => {
    setShowForm(false);
  };

  const closeConfirmationDeleteModal = () => {
    setShowConfirmationDelete(false);
    setItemToDelete(null);
  };

  const handleOpenConfirmationDelete = (event: any, index: number) => {
    event.stopPropagation();
    event.preventDefault();

    setShowConfirmationDelete(true);
    setItemToDelete(index);
  };

  const handleOpenForm = (event: any, index: number, notification?: INotification) => {
    event.stopPropagation();
    event.preventDefault();

    setForm(
      <NotificationForm
        index={index}
        notification={notification}
        onSubmit={handleSubmitNews}
        onModalClose={closeModal}
      />
    );
    setShowForm(true);
  };

  const moveItem = (event: any, from: number, to: number) => {
    event.stopPropagation();
    event.preventDefault();

    setExpanded(`panel${to}`);
    let items = [...notificationsList];
    const f = items.splice(from, 1)[0];
    items.splice(to, 0, f);
    handleSubmit(items);
  };

  return (
    <Box className={classes.wrapper}>
      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={handleSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="success">{t('settings.successAlert')}</Alert>
      </Snackbar>

      <Dialog
        fullScreen={fullScreen}
        open={showForm}
        maxWidth={'lg'}
        fullWidth
        onClose={() => setShowForm(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>{form}</DialogContent>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={showConfirmationDelete}
        maxWidth={'lg'}
        onClose={() => setShowConfirmationDelete(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{t('settings.confirmationDeleteMsg')}</DialogTitle>
        <DialogContent />
        <DialogActions>
          <Button autoFocus onClick={closeConfirmationDeleteModal} color="primary">
            {t('settings.cancel')}
          </Button>
          <Button color="primary" onClick={handleDelete} autoFocus>
            {t('settings.delete')}
          </Button>
        </DialogActions>
      </Dialog>

      <Paper elevation={1}>
        {loading ? (
          <Box p={4} pt={8} pb={8} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : notificationsList.length > 0 ? (
          notificationsList.map((item, i) => (
            <NotificationItem
              key={i}
              index={i}
              notification={item}
              totalItems={notificationsList.length}
              expanded={expanded}
              onExpand={setExpanded}
              onMove={moveItem}
              onEdit={handleOpenForm}
              onDelete={handleOpenConfirmationDelete}
            />
          ))
        ) : (
          <Alert severity="info">Keine Meldungen gespeichert.</Alert>
        )}
      </Paper>

      <Box display={'flex'} flexDirection={'row'} justifyContent={'end'} mt={2}>
        <Button
          variant="contained"
          color="secondary"
          component="button"
          onClick={(event: any) => handleOpenForm(event, notificationsList.length)}
        >
          {t('settings.new')}
        </Button>
      </Box>
    </Box>
  );
};

export default Notifications;
