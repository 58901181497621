export const i18n_en = {
  title: 'Lernweltmanager',
  avvTable: {
    title: 'AVV-Zustimmungen',
    new: 'Neuer Eintrag',
    search: 'Nach Mandant suchen...',
    errors: {
      fetch: 'Fehler beim Laden der Daten. Bitte laden sie die Seite erneut.',
    },
    header: {
      mandant: 'Mandant',
      user: 'Benutzer',
      date: 'Erstellungsdatum',
      source: 'Ursprung',
      version: 'LWM-Version',
    },
    add: {
      title: 'AVV-Zustimmung anlegen',
      failed: 'Anlage der neuen AVV-Zustimmung ist fehlgeschlagen.',
      mandantExists: 'Es ist bereits eine Zustimmung für diese MandantId hinterlegt.',
      submit: 'AVV-Zustimmung anlegen',
    },
  },
};
