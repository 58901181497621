import { Box, Typography } from '@material-ui/core';
import { FunctionComponent, ReactNode } from 'react';

interface ILwmPageProps {
  title: string;
  children?: ReactNode;
}

export const LwmPage: FunctionComponent<ILwmPageProps> = ({ title, children }) => {
  return (
    <>
      <Box mt={2}>
        <Typography variant="h1" component="h2" className="m-4">
          {title}
        </Typography>
      </Box>
      {children}
    </>
  );
};
