import ReactDOM from 'react-dom';
import { IMicroFrontendInstance } from '@hmg-aka-lu/cockpit-frontend/dist-types/exports';

// import {
//   createBrowserHistory,
//   History,
// } from 'history';
import './i18n';

import './theme/FontFace.css';
import LightHouseThemeProvider from './theme/LightHouseThemeProvider';
import mergedConfig, { MICROFRONTEND_ID } from './config';
import JWTContext from './components/JWTContext';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AvvAgreementsPage } from './pages/avvAgreements';
import { MandantenPage } from './pages/mandanten';
import { SettingsPage } from './pages/settings';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PriceListManagement } from './components/price-lists/price-list-management';
import { AlertSnackBar } from './components/alert-snackbar/alert-snackbar';

declare global {
  interface Window {
    [MICROFRONTEND_ID]: IMicroFrontendInstance;
  }
}

const microFrontendInstance: IMicroFrontendInstance = (containerId = 'root', history: any, microfrontendMeta) => {
  const queryClient = new QueryClient();

  return {
    render: () => {
      ReactDOM.render(
        <LightHouseThemeProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <JWTContext.Provider value={{ getJWT: microfrontendMeta.getJWT }}>
              <BrowserRouter>
                <Routes>
                  <Route path={`/${MICROFRONTEND_ID}/avv-zustimmungen`} element={<AvvAgreementsPage />} />
                  <Route path={`/${MICROFRONTEND_ID}/mandanten`} element={<MandantenPage />} />
                  <Route path={`/${MICROFRONTEND_ID}/settings`} element={<SettingsPage />} />
                  <Route path={`/${MICROFRONTEND_ID}/price-lists`} element={<PriceListManagement />} />
                  <Route path="*" element={<Navigate replace to={`/${MICROFRONTEND_ID}/settings`} />} />
                </Routes>
              </BrowserRouter>
            </JWTContext.Provider>
          </QueryClientProvider>
          <AlertSnackBar />
        </LightHouseThemeProvider>,
        document.getElementById(containerId)
      );
    },
  };
};

window.lwmSettings = microFrontendInstance;

if (mergedConfig.STANDALONE) {
  const microFrontend = microFrontendInstance(
    'root',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    undefined,
    {
      getJWT: async () => {
        return '';
      },
      hasCockpitAccess: () => true,
      hasRequiredGroup: () => true,
      authenticatedContext: {
        groups: [],
      },
      relativeUrl: '12312',
    }
  );
  microFrontend.render();
}
