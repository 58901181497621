import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ChangeEvent, FunctionComponent, ReactNode, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import JWTContext from '../JWTContext';
import { usePriceListProductsQuery } from '../../queries/price-list-queries';
import { EditPriceList } from './edit-price-list';
import { Alert } from '@material-ui/lab';
import { AddNewPriceTable } from './add-new-price-list';
import { DeletePriceList } from './delete-price-list';

const useStyles = makeStyles((_theme) => ({
  componentWrapper: {
    padding: '24px',
  },
  editTableContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '24px',
    paddingTop: '12px',
  },
  selectContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'row',
  },
  formControlSelect: {
    minWidth: '400px',
  },
  warningAlert: {
    marginTop: '12px',
  },
}));

export const PriceListManagement: FunctionComponent = () => {
  const classes = useStyles();
  const { getJWT } = useContext(JWTContext);
  const {
    data: products,
    isLoading: productsLoading,
    isError: productsQueryFailed,
  } = usePriceListProductsQuery(getJWT);
  const [selectedProduct, setSelectedProduct] = useState('');

  const [newProducts, setNewProducts] = useState<string[]>([]);

  const availableProducts = Array.from(new Set([...products, ...newProducts])).sort((a, b) => a.localeCompare(b));

  const handleOnNewProduct = (product: string) => {
    setSelectedProduct(product);
    setNewProducts((old) => [...old, product]);
  };

  const priceListSelectChange = (event: ChangeEvent<{ value: unknown }>, _child: ReactNode) => {
    setSelectedProduct(event.target.value as string);
  };

  return (
    <Box className={classes.componentWrapper}>
      <Typography variant={'h2'}>
        <Trans i18nKey={'priceLists.header'} />
      </Typography>
      {!productsLoading ? (
        <Box className={classes.selectContainer}>
          <FormControl className={classes.formControlSelect} variant={'outlined'}>
            <InputLabel id={'price-list-select-label'}>
              <Trans i18nKey={'priceLists.selectLabel'} />
            </InputLabel>
            <Select
              labelId={'price-list-select-label'}
              onChange={priceListSelectChange}
              value={selectedProduct}
              label={<Trans i18nKey={'priceLists.selectLabel'} />}
            >
              {availableProducts.map((product) => (
                <MenuItem value={product} key={`select-price-list-${product}`}>
                  {product}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <AddNewPriceTable getJWT={getJWT} onNewProduct={handleOnNewProduct} />
        </Box>
      ) : (
        <CircularProgress />
      )}
      {!!selectedProduct && (
        <Box className={classes.editTableContainer}>
          <EditPriceList getJWT={getJWT} product={selectedProduct} />
          {products.includes(selectedProduct) && <DeletePriceList getJWT={getJWT} product={selectedProduct} />}
          <Alert severity={'info'}>
            <Trans i18nKey={'priceLists.note'} />
          </Alert>
        </Box>
      )}
      {!selectedProduct && (
        <Alert severity={'warning'} className={classes.warningAlert}>
          <Trans i18nKey={'priceLists.noPriceListSelected'} />
        </Alert>
      )}
      {productsQueryFailed && (
        <Alert severity={'error'} className={classes.warningAlert}>
          <Trans i18nKey={'priceLists.fetchFailed'} />
        </Alert>
      )}
    </Box>
  );
};
