import { FunctionComponent } from 'react';
import { LwmPage } from '../components/lwm-page';
import { useTranslation } from 'react-i18next';
import { MandantenList } from '../components/mandanten/MandantenList';

export const MandantenPage: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <LwmPage title={t('pages.mandanten')}>
      <MandantenList />
    </LwmPage>
  );
};
