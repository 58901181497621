import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Typography,
  CircularProgress,
  Divider,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { getApiService } from '../../api/api-request';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
  },
  colorPicker: {
    marginBottom: theme.spacing(4),
    marginRight: '40px',
    '& .sample': {
      position: 'absolute',
      right: '-40px',
      bottom: 0,
      width: 24,
      height: 24,
      marginLeft: '.5rem',
    },
  },
  tabContent: {
    padding: theme.spacing(4),
    backgroundColor: '#e1e1e1',
  },
}));

enum Colors {
  'Grey' = '#252525',
  'Orange' = '#FF9300',
  'Blue' = '#00ABF7',
  'Pink' = '#ED1E79',
  'Green' = '#03B9B6',
}

interface IBannerProps {
  getJWT: () => Promise<string | undefined | null>;
}

const Banner: FunctionComponent<IBannerProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [textDE, setTextDE] = useState<string>('');
  const [linkDE, setLinkDE] = useState<string | undefined>();
  const [textEN, setTextEN] = useState<string>('');
  const [linkEN, setLinkEN] = useState<string | undefined>();
  const [color, setColor] = useState<string>(Colors['Grey']);

  const handleChangeColor = (event: React.ChangeEvent<{ value: unknown }>) => {
    setColor(event.target.value as string);
  };

  const handleChangeEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnabled(event.target.checked);
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const settings = await apiService.getSettings('Banner');
        if (settings) {
          const banner = settings.data;
          setEnabled(banner.enabled);
          setTextDE(banner.translations.de.text);
          setTextEN(banner.translations.en.text);
          setLinkDE(banner.translations.de.link);
          setLinkEN(banner.translations.en.link);
          setColor(banner.backgroundColor);
        }
      } catch (error) {
        // setError(`${t('settings.failAlert')}: ${String(error)}`);
      }
      setLoading(false);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSnackbar = () => {
    setShowAlert(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await apiService.updateSettings('Banner', {
        enabled,
        backgroundColor: color,
        translations: {
          de: {
            text: textDE,
            link: linkDE,
          },
          en: {
            text: textEN,
            link: linkEN,
          },
        },
      });
      setShowAlert(true);
    } catch (error) {
      setError(`${t('settings.failAlert')}: ${String(error)}`);
    }
    setLoading(false);
  };

  const apiService = getApiService(props.getJWT);

  return (
    <Paper elevation={1} className={classes.tabContent}>
      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={handleSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="success">{t('settings.successAlert')}</Alert>
      </Snackbar>
      <Paper>
        {loading ? (
          <Box p={4} pt={8} pb={8} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <Box p={4} pt={4} pb={4} display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h2" style={{ margin: '0' }}>
                {t('settings.titleNewsBanner')}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enabled}
                    onChange={handleChangeEnabled}
                    name="enabled"
                    color="primary"
                    value={enabled}
                  />
                }
                style={{ marginRight: 0 }}
                label={t('settings.form.enabled')}
              />
            </Box>
            <FormControl className={classes.colorPicker}>
              <span className={'sample'} style={{ backgroundColor: enabled ? color : '#DDD' }} />
              <InputLabel id="colorFieldLabel" disabled={!enabled}>
                {t('settings.form.color')}
              </InputLabel>
              <Select
                labelId="colorFieldLabel"
                onChange={handleChangeColor}
                value={color}
                id="colorField"
                disabled={!enabled}
              >
                {Object.keys(Colors).map((e: any, i: number) => {
                  const value = Colors[e as keyof typeof Colors];
                  return (
                    <MenuItem key={i} value={value}>
                      <span style={{ width: 24, height: 24, backgroundColor: value, marginRight: '.5rem' }} />
                      {e}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Grid container>
              <Grid item xs style={{ margin: '0 2rem 0 0' }}>
                <Typography variant="h6">{t('settings.languages.de')}</Typography>
                <TextField
                  id="textFieldDE"
                  label={t('settings.form.text')}
                  placeholder={t('settings.form.textPlaceholder')}
                  disabled={!enabled}
                  value={textDE}
                  onChange={(event) => setTextDE(event.target.value)}
                  className={classes.formControl}
                  fullWidth={true}
                  multiline
                />
                <TextField
                  id="linkFieldDE"
                  label={t('settings.form.link')}
                  placeholder={t('settings.form.linkPlaceholder')}
                  onChange={(event) => setLinkDE(event.target.value)}
                  value={linkDE}
                  fullWidth={true}
                  className={classes.formControl}
                  disabled={!enabled}
                />
              </Grid>
              <Divider orientation="vertical" style={{ opacity: 0.25 }} flexItem />
              <Grid item xs style={{ margin: '0 0 0 2rem' }}>
                <Typography variant="h6">{t('settings.languages.en')}</Typography>
                <TextField
                  id="textFieldEN"
                  label={t('settings.form.text')}
                  placeholder={t('settings.form.textPlaceholder')}
                  disabled={!enabled}
                  value={textEN}
                  fullWidth={true}
                  onChange={(event) => setTextEN(event.target.value)}
                  className={classes.formControl}
                  multiline
                />
                <TextField
                  id="linkFieldEN"
                  label={t('settings.form.link')}
                  placeholder={t('settings.form.linkPlaceholder')}
                  onChange={(event) => setLinkEN(event.target.value)}
                  value={linkEN}
                  fullWidth={true}
                  className={classes.formControl}
                  disabled={!enabled}
                />
              </Grid>
            </Grid>
            <Box pt={3} display="flex" justifyContent="end">
              <Button variant="contained" color="secondary" component="button" onClick={handleSubmit}>
                {t('settings.save')}
              </Button>
            </Box>
          </Box>
        )}
      </Paper>
    </Paper>
  );
};

export default Banner;
