import { Button, FormControlLabel, Switch, Typography } from '@material-ui/core';
import { FunctionComponent, useContext, useState } from 'react';
import { IMandant, SsoType } from '../../../../sharedTypes';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import JWTContext from '../JWTContext';
import { getApiService } from '../../api/api-request';
import { Alert } from '@material-ui/lab';

interface IEditMandantProps {
  mandant: IMandant;
  onClose: () => void;
}

interface IEditMutationParams {
  id: string;
  scormDownloadEnabled: boolean;
  ssoType: SsoType | null;
}

const EditMandant: FunctionComponent<IEditMandantProps> = ({ mandant, onClose }) => {
  const [scormDownloadEnabled, setScormDownloadEnabled] = useState(!!mandant.scormDownloadEnabled);
  const [updateError, setUpdateError] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { getJWT } = useContext(JWTContext);
  const apiService = getApiService(getJWT);

  const editMutation = useMutation<IMandant, void, IEditMutationParams>(
    (variables) => apiService.updateMandant(variables),
    {
      onSuccess: () => {
        setUpdateError(false);
        queryClient.invalidateQueries(['mandanten']);
        onClose();
      },
      onError: () => {
        setUpdateError(true);
      },
    }
  );

  return (
    <>
      <Typography variant="h2">{mandant.name}</Typography>
      <div>
        {updateError && <Alert severity="error">This is an error alert — check it out!</Alert>}
        <FormControlLabel
          control={
            <Switch
              checked={scormDownloadEnabled}
              onChange={(event) => setScormDownloadEnabled(event.target.checked)}
              name="scorm-download"
            />
          }
          label={t('mandantenTable.edit.scormDownload')}
        />
      </div>
      <Button disabled={editMutation.isLoading} variant="contained" onClick={() => onClose()}>
        {t('mandantenTable.edit.cancel')}
      </Button>
      <Button
        disabled={editMutation.isLoading}
        color="primary"
        variant="contained"
        onClick={() => {
          editMutation.mutate({ id: mandant.id, scormDownloadEnabled, ssoType: mandant.ssoType });
        }}
      >
        {t('mandantenTable.edit.save')}
      </Button>
    </>
  );
};

export default EditMandant;
