import React, { ChangeEvent, FunctionComponent, useContext, useEffect, useState } from 'react';

import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { getApiService } from '../../api/api-request';
import JWTContext from '../JWTContext';
import {
  Box,
  Drawer,
  FormControl,
  LinearProgress,
  MenuItem,
  Select,
  Snackbar,
  SnackbarCloseReason,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  makeStyles,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { IMandant, SsoType } from '../../../../sharedTypes';
import EditMandant from './EditMandant';
import { useMutation } from '@tanstack/react-query';
import { Alert, Color, Pagination } from '@material-ui/lab';
import { AvailableSsoTypes } from '../../config';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    '& .MuiPaper-root': {
      backgroundColor: '#fff !important',
    },
  },
  drawerContent: {
    padding: theme.spacing(5),
    height: '100%',
  },
}));

interface IEditMutationParams {
  id: string;
  scormDownloadEnabled: boolean;
  ssoType: SsoType | null;
}

const PAGE_SIZE = 25;

export const MandantenList: FunctionComponent<unknown> = () => {
  const { getJWT } = useContext(JWTContext);
  const apiService = getApiService(getJWT);
  const queryClient = useQueryClient();
  const [searchText, setSearchText] = useState('');
  const classes = useStyles();
  const [selectedMandant, setSelectedMandant] = useState<IMandant | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [updateStatusText, setUpdateStatusText] = useState('');
  const [updateStatusSeverity, setUpdateStatusSeverity] = useState<Color>('success');
  const [loadingRowId, setLoadingRowId] = useState<string | null>(null);
  const { t } = useTranslation();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: ['mandanten'],
    queryFn: apiService.getMandanten,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.next;
    },
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage]);

  useEffect(() => {
    apiService.syncMandanten().then((result) => {
      if (result.status === 'out-of-sync') {
        queryClient.invalidateQueries(['mandanten']);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const mandantFilter = (mandant: IMandant) => {
    const searchTextToLower = searchText.toLowerCase();
    return (
      mandant.id?.toLowerCase().includes(searchTextToLower) ||
      mandant.name?.toLowerCase().includes(searchTextToLower) ||
      mandant.description?.toLowerCase().includes(searchTextToLower)
    );
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setUpdateStatusText('');
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>, mandant: IMandant) => {
    setLoadingRowId(mandant.id);

    editMutation.mutate({
      id: mandant.id,
      scormDownloadEnabled: mandant.scormDownloadEnabled,
      ssoType: event.target.value === '' ? null : (event.target.value as SsoType),
    });
  };

  const filteredMandanten = searchText
    ? data?.pages.flatMap((page) => page.items).filter(mandantFilter)
    : data?.pages.flatMap((page) => page.items);

  const pagedMandanten = filteredMandanten?.slice(PAGE_SIZE * (currentPage - 1), PAGE_SIZE * currentPage) ?? [];

  const editMutation = useMutation<IMandant, void, IEditMutationParams>(
    (variables) => apiService.updateMandant(variables),
    {
      onSuccess: (data) => {
        setUpdateStatusSeverity('success');
        setUpdateStatusText(t('mandantenTable.updateSuccess', { mandant: data.name }));
        queryClient.invalidateQueries(['mandanten']);
        //onClose();
      },
      onError: (error, variables) => {
        setUpdateStatusSeverity('error');
        setUpdateStatusText(t('mandantenTable.updateError', { mandant: variables.id }));
      },
      onSettled: () => {
        setLoadingRowId(null);
      },
    }
  );

  return (
    <>
      <Box>
        <TextField
          style={{ width: '100%' }}
          label={<Trans i18nKey={'avvTable.search'} />}
          variant={'outlined'}
          value={searchText}
          onChange={handleInputChange}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans i18nKey={'mandantenTable.header.id'} />
              </TableCell>
              <TableCell>
                <Trans i18nKey={'mandantenTable.header.name'} />
              </TableCell>
              <TableCell>
                <Trans i18nKey={'mandantenTable.header.scormDownload'} />
              </TableCell>
              <TableCell>
                <Trans i18nKey={'mandantenTable.header.sso'} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagedMandanten
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((mandant) => (
                <TableRow key={`mandant-table-row-${mandant.id}`}>
                  <TableCell size={'small'}>{mandant.id}</TableCell>
                  <TableCell size={'small'}>{mandant.name}</TableCell>
                  <TableCell size={'small'}>
                    <Switch
                      checked={mandant.scormDownloadEnabled}
                      onChange={(event) =>
                        editMutation.mutate({
                          id: mandant.id,
                          scormDownloadEnabled: event.target.checked,
                          ssoType: mandant.ssoType,
                        })
                      }
                      name="scorm-download"
                    />
                  </TableCell>
                  {loadingRowId === mandant.id ? (
                    <TableCell size={'small'}>
                      <LinearProgress />
                    </TableCell>
                  ) : (
                    <TableCell size={'small'}>
                      <FormControl fullWidth>
                        <Select
                          labelId="sso-select-label"
                          id="sso-select"
                          value={mandant.ssoType ?? ''}
                          label="SSO"
                          displayEmpty
                          onChange={(event) => handleChange(event, mandant)}
                        >
                          <MenuItem value={''}>----</MenuItem>
                          {AvailableSsoTypes.map((ssoType) => (
                            <MenuItem key={`sso-type-${ssoType}`} value={ssoType}>
                              {t(`mandantenTable.header.${ssoType}`)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Pagination
          count={filteredMandanten ? Math.ceil(filteredMandanten?.length / PAGE_SIZE) : 0}
          page={currentPage}
          onChange={(_event, value) => setCurrentPage(value)}
        />
        <Drawer
          PaperProps={{ style: { width: '40%', height: '100%' } }}
          className={classes.drawer}
          anchor="right"
          open={selectedMandant !== null}
          onClose={() => setSelectedMandant(null)}
        >
          <div className={classes.drawerContent}>
            {selectedMandant && <EditMandant mandant={selectedMandant} onClose={() => setSelectedMandant(null)} />}
          </div>
        </Drawer>

        <Snackbar open={updateStatusText.length > 0} autoHideDuration={2000} onClose={handleCloseSnackbar}>
          <Alert onClose={() => setUpdateStatusText('')} severity={updateStatusSeverity}>
            {updateStatusText}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};
